var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "w-4",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" },
    },
    [
      _c("title", [_vm._v("Dashboard")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M8.5,11.3A.49.49,0,0,1,9,10.81a.5.5,0,0,1,0,1A.49.49,0,0,1,8.5,11.3Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3,11.81H2a.15.15,0,0,0,0,.06,7.07,7.07,0,0,0,.4,1.81H15.6A6.45,\n        6.45,0,0,0,16,11.81H15a.5.5,0,0,1,0-1h1a7.06,7.06,0,0,0-.69-2.55L15,\n        8.41c-.22.12-.42.25-.64.35A.49.49,0,0,1,14,7.87l.83-.49A7.23,7.23,0,\n        0,0,12.94,5.5l-.08.13-.44.75a.5.5,0,0,1-.92-.18.58.58,0,0,1,.09-.38L12.07,\n        5a7.27,7.27,0,0,0-2.58-.69v1a.5.5,0,0,1-1,0c0-.22,0-.43,0-.64V4.32A7,7,0,\n        0,0,5.94,5L6,5.13c.15.26.3.52.44.79a.45.45,0,0,1-.23.64.47.47,\n        0,0,1-.64-.19c-.17-.29-.33-.58-.51-.87A7,7,0,0,0,3.19,7.38l.1.07.78.46a.42.42,\n        0,0,1,.23.41.49.49,0,0,1-.75.42c-.29-.15-.57-.32-.86-.48A7.06,7.06,0,0,0,2,\n        10.81H3a.5.5,0,1,1,0,1Zm5.74-2a1.89,1.89,0,0,1,.83.08.15.15,0,0,0,.18,\n        0L10.89,8.7a.55.55,0,0,1,.41-.18.47.47,0,0,1,.46.3.46.46,0,0,\n        1-.11.53c-.39.41-.8.82-1.2,1.22a.17.17,0,0,0,0,.21,1.52,1.52,0,0,1-1.49,\n        2A1.55,1.55,0,0,1,7.5,11.46,1.48,1.48,0,0,1,8.72,9.84Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }